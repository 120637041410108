a.mceButton16 img.mceIcon {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.CT-hide .del,
.CT-hide .del {
  display: none;
}

.CT-hide .ins,
.CT-hide .ins {
  color: #333333;
  background: none !important;
  border: none !important;
  text-decoration: none;
}

.del {
  text-decoration: line-through;
  color: inherit !important;
}
.ins {
  color: inherit !important;
}

.cts-1 {
  color: green;
}
.del.cts-1 img {
  border-color: green;
}
.ins.cts-1 img,
.bcts-1 {
  background-color: green;
}
.cts-2 {
  color: #c02000;
}
.del.cts-2 img {
  border-color: #c02000;
}
.ins.cts-2 img,
.bcts-2 {
  background-color: #c02000;
}
.cts-3 {
  color: #004090;
}
.del.cts-3 img {
  border-color: #004090;
}
.ins.cts-3 img,
.bcts-3 {
  background-color: #004090;
}
.cts-4 {
  color: #f06000;
}
.del.cts-4 img {
  border-color: #f06000;
}
.ins.cts-4 img,
.bcts-4 {
  background-color: #f06000;
}
.cts-5 {
  color: purple;
}
.del.cts-5 img {
  border-color: purple;
}
.ins.cts-5 img,
.bcts-5 {
  background-color: purple;
}
.cts-6 {
  color: #801080;
}
.del.cts-6 img {
  border-color: #801080;
}
.ins.cts-6 img,
.bcts-6 {
  background-color: #801080;
}
.cts-7 {
  color: #1080b0;
}
.del.cts-7 img {
  border-color: #1080b0;
}
.ins.cts-7 img,
.bcts-7 {
  background-color: #1080b0;
}

.ice-avoid {
  color: red;
}

.del img {
  border-top-style: dotted;
  border-bottom-style: dotted;
  border-width: 4px;
}

.ins img {
  padding: 3px;
}

.ins .del img {
  padding: 0px 3px;
}
