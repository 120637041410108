@import 'variables';
@import 'node_modules/@angular/material/theming';

$custom-primary-palette: (
  50: #e0eaf3,
  100: #b3cbe2,
  200: #80a9cf,
  300: #4d86bc,
  400: #266cad,
  500: $blue,
  600: #004b97,
  700: #00418d,
  800: #003883,
  900: #002872,
  A100: #a1b9ff,
  A200: #6e94ff,
  A400: #3b6eff,
  A700: #215bff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$custom-green-palette: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: $green,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text
  )
);

$custom-typography: mat-typography-config(
  $font-family: $primary-font-family
);
@include mat-core($custom-typography);
$custom-theme-primary: mat-palette($custom-primary-palette);
$custom-theme-accent: mat-palette($custom-green-palette);
$custom-theme-warn: mat-palette($mat-red);
$custom-theme: mat-light-theme(
  $custom-theme-primary,
  $custom-theme-accent,
  $custom-theme-warn
);
@include angular-material-theme($custom-theme);

mat-button-toggle-group.mat-button-toggle-group {
  border-color: $blue;

  mat-button-toggle.mat-button-toggle {
    background-color: #fff;
    color: $blue;

    button.mat-button-toggle-button {
      height: 100%;
      padding: 6px 10px;
    }

    &.mat-button-toggle-checked {
      background-color: $blue;
      color: #fff;
    }

    .mat-button-toggle-label-content {
      line-height: initial;
    }

    + .mat-button-toggle {
      border-color: $blue;
    }
  }
}

button.mat-primary {
  &.mat-stroked-button {
    color: $blue;
    border-color: $blue;
  }

  &.mat-raised-button {
    background-color: $blue;
  }
}

mat-tooltip-component {
  .mat-tooltip {
    max-width: 200px;
    text-align: center;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.75);

    &.long-tooltip {
      max-width: 400px;
      text-align: left;
    }

    &.blue-tooltip {
      background-color: #00529f;
    }
  }
}

snack-bar-container.mat-snack-bar-container {
  padding: 0;
  app-custom-snackbar {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
}

mat-checkbox {
  > label {
    margin-bottom: 0;
  }
}

mat-select {
  border: 1px solid #ccc;
}

.mat-select-button-group {
  display: flex;
  flex-direction: column;

  button {
    height: 100%;
    padding: 1rem;
    .mat-button-wrapper {
      padding-right: 1rem;
    }
    mat-icon {
      position: absolute;
      right: 0.5rem;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  mat-select {
    visibility: hidden;
    height: 0;
  }

  button,
  mat-select {
    padding: 0 1rem;
  }
}

mat-dialog-container.mat-dialog-container {
  .mat-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;

    h3,
    h4,
    h5 {
      margin: 0;
    }
  }

  mat-dialog-content.mat-dialog-content {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1rem 1.5rem;
  }

  mat-dialog-actions.mat-dialog-actions {
    justify-content: flex-end;
    margin: 0;
    padding: 1rem 1rem;
    border-top: 1px solid #dee2e6;
  }
}

ngx-mat-select-search {
  .mat-select-search-inner {
    padding-top: 1px;
    .mat-select-search-input {
      width: 100%;
    }
  }
}

mat-radio-button {
  .mat-ripple-element {
    left: 0;
  }
}

@media print {
  mat-select {
    .mat-select-trigger {
      width: 100%;
    }
    .mat-select-arrow-wrapper {
      display: none;
    }
  }
}
