$left-sidebar-width: 60px;

$alert-color: #f44336;

$green: #58ad3f;
$red: #cc0000;
$yellow: #f7cd4b;
$blue: #00529f;
$grey: #ddd;
$warning-color: #e89600;
$primary-font-family: Roboto, 'Helvetica Neue', sans-serif;
$secondary-font-family: Montserrat, sans-serif;
$apiRoot: 'https://api.sandbox.seagage.com';
$grey-background-color: #d9d9d9;

$grey-border-color: #bfbfbf;

@mixin printable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  z-index: 100000;
  background-color: #ffffff;
  -webkit-print-color-adjust: exact;
}

@mixin content-wrapper {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;

  @media print {
    max-width: 100%;
  }
}
