@import 'assets/styles/theme';
@import 'app/common/components/e3-async-button/e3-async-button.directive';
@import '~handsontable/dist/handsontable.full.css';
$fa-font-path: '~font-awesome/fonts';
@import '~font-awesome/scss/font-awesome.scss';
@import 'app/common/scss/variables';
@import 'app/common/scss/angular-material-custom';

body {
  font-size: 14px;
  font-family: $primary-font-family;
  font-weight: 400;
  margin: 0;
  line-height: 1.5;
  color: #212529;
  text-align: left;

  &.pdf-export {
    button.pdf-export-btn {
      display: none;
    }
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

input,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &:disabled {
    color: inherit;
    background-color: inherit;
    cursor: default;
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

mat-radio-group mat-radio-button label {
  margin-bottom: 0;
}

.mat-tab-body {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 1em;
  border-top: 0;
}

table {
  border-collapse: collapse;
  &.table {
    width: 100%;
    color: #212529;
    th,
    td {
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
    }
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &.table-bordered {
    th,
    td {
      border: 1px solid #dee2e6;
    }
  }

  &.table-hover {
    tbody tr:hover {
      color: #212529;
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}

h1,
.h1 {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.text-center {
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.site-tbox {
  outline: none;
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  margin: 15px 0;
}

// used in step builder ckeditor source mode
.border-content {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border: 2px solid #ccc;
}

.ck-balloon-panel_visible {
  z-index: 99999 !important;
}

.completed-check {
  color: $green;
}

.download-link {
  display: flex;
  justify-content: center;
  span.file-name {
    padding: 5px 15px;
    border: 1px solid black;
  }
  a.file-link {
    background-color: #00529f;
    color: white;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
}

#toast-container > div {
  opacity: 1 !important;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-35 {
  width: 35%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

div.cdk-overlay-container {
  z-index: 1051;
}

.composable-description {
  max-width: 800px;
  margin: 0 auto;

  p {
    text-align: center;
    margin-bottom: 0;
  }
}

snack-bar-container.mat-snack-bar-container {
  padding: 0;
  app-custom-snackbar {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.mat-select-panel.module-videos-select {
  max-height: 75vh !important;
}

.showing-in-printable-mode {
  display: none;
}

.dashboards-title {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

@media print {
  #freshworks-container {
    display: none;
  }
  .composable-description {
    -webkit-print-color-adjust: exact;
    max-width: none;
  }

  .showing-in-printable-mode {
    display: block;
  }

  .not-show-in-printable-mode {
    display: none !important;
  }

  .bullet-printable-checkbox {
    display: flex;
    align-items: center;
    padding-left: 10px;

    &:before {
      content: '';
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: black;
    }

    input {
      display: none !important;
    }

    label {
      margin-left: 10px !important;
    }
  }

  .mat-pseudo-checkbox-checked {
    display: none !important;

    &:after {
      display: none !important;
    }
  }

  .content-wrapper {
    display: block;
    page-break-inside: avoid;
  }

  .step_19660,
  .step_19659,
  .step_19670 {
    .col-2 {
      display: none;
    }
  }

  .step_19664 {
    .htCore {
      tbody {
        tr:nth-child(-n + 8) {
          display: none;
        }
        tr:nth-child(n + 8):nth-child(-n + 12) {
          td:nth-child(5) {
            display: none;
          }
        }
        tr:nth-child(-n + 8) {
          display: none;
        }
      }
    }
  }

  .step_19665 {
    .htCore {
      tbody {
        tr:nth-child(-n + 5) {
          display: none;
        }
      }
    }
  }
}
